

    export default {
        name: '',
        props: {},
        data() {
            return {
                contextGrid: null,
                canvasAddList: [
                    {width: 'boardWidth', height: 'boardHeight', className: 'board-canvas-bcg review', id: 'board-canvas-review', contextKey: 'gamContextReview'},
                    {width: 'boardWidth', height: 'boardHeight', className: 'board-canvas-bcg grid', id: 'board-canvas-grid', contextKey: 'contextGrid'},
                    {width: 'boardWidth', height: 'boardHeight', className: 'board-canvas-bcg', id: 'board-canvas-bcg'},
                    {width: 'boardWidth', height: 'boardHeight', className: 'board-canvas-bcg gam', id: 'board-canvas-gam', contextKey: 'gamContext'},
                ],
                eleTime: null
            };
        },
        methods: {
            getId(name){
                return `${name}_${this.eleTime}`
            },
            async canvasInit(){
                return this.contextGrid
                // let id = this.getId('board-canvas-grid')
                // this.setDebugInto({contextGridSet: document.getElementById(id).tagName})
                // this.contextGrid = document.getElementById(id).getContext('2d')
                // if(!this.contextGrid){
                //     let t = (new Date()).getSeconds()
                //     this.setDebugInto({contextGridObj: `${document.getElementById(id).getContext('2d')} ${t}`})
                //     await this.createCanvas()
                //     await this._common.settimeout(1000)
                //     return this.canvasInit()
                // }
                // this.setDebugInto({contextGridObj: 'contextGrid is null'})
            },
            clearGrid(){
                if(!this.contextGrid) return
                this.contextGrid.clearRect(0, 0, this.boardWidth, this.boardHeight)
            },
            async removeCanvas(){

                let {removeCanvas: list} = this
                let box = this.$refs.boardBox
                for(let i in list){
                    let ele = document.getElementById(this.getId(list[i].id))
                    if(!ele) continue
                    box.removeChild(ele)
                }


            },
            async createCanvas(){
                await this._common.nextTick.call(this)
                let box = this.$refs.boardBox

                let list = this.canvasAddList
                for(let i in list){
                    let {id, contextKey, className} = list[i]
                    let data = this._common.getCanvasData(id)
                    let {element, context} = data || {}
                    element.width = this.boardWidth
                    element.height = this.boardHeight
                    element.className = className
                    box.appendChild(element)
                    if(this[contextKey] !== undefined) this[contextKey] = context
                }

            },
            async gridInit(){
                let t = (new Date()).getSeconds()
                this.setDebugInto({gridInit: `gridInit: ${t}`})
                await this._common.nextTick.call(this)
                await this.canvasInit()
                this.clearGrid()

                let WGo = this.board.WGo
                let board = this.board.board
                let bb = 25
                let fontSize = bb
                var tmp, coordSystem = board.coordSystem;
                this.contextGrid.moveTo(0, 0);
                this.setDebugInto({gridend: `gridend: ${t}`})
                this.contextGrid.strokeStyle = WGo.lineColor
                this.contextGrid.fillStyle = WGo.coordColor
                this.contextGrid.beginPath();
                this.contextGrid.textAlign = "center";
                this.contextGrid.textBaseline = "middle";
                let width = board.width
                let ba = width * 0.00375
                if (ba > 3) ba = 3
                this.contextGrid.lineWidth = 4
                var tx = Math.round(board.left),
                    ty = Math.round(board.top),
                    bw = Math.round(board.fieldWidth * (board.size - 1)),
                    bh = Math.round(board.fieldHeight * (board.size - 1));

                this.contextGrid.strokeRect(tx - board.ls, ty - board.ls, bw, bh);
                let rey = bb / 2 + 10
                let rex = bb / 2 + 10
                var k = 65, rep = 0, xs, ys;
                if (!coordSystem) {
                    coordSystem = 1;
                }
                switch (coordSystem) {
                    case 1:
                        xs = String.fromCharCode(65);
                        ys = board.size;
                        break;
                    case 2:
                        xs = 1;
                        ys = xs;
                        break;
                    case 3:
                        xs = String.fromCharCode(65);
                        ys = 1;
                        break
                }
                if (coordSystem != 4) {
                    this.contextGrid.font = `normal bold ${fontSize}px Arial`;


                    this.contextGrid.textAlign = "center"
                    this.contextGrid.fillText(xs, Math.round(board.getX(0)) - board.ls, rey);
                    this.contextGrid.fillText(ys, rex, Math.round(board.getY(0)) - board.ls + rep);
                }
                for (var i = 1; i < board.size - 1; i++) {
                    let lineWidth = width * 0.001625
                    if (lineWidth > 1) lineWidth = 1
                    this.contextGrid.lineWidth = lineWidth
                    this.contextGrid.lineWidth = 2
                    if (coordSystem == 1) {
                        if (i >= 8) {
                            k = i + 66;
                            xs = String.fromCharCode(i + 66);
                        } else {
                            k = i + 65;
                            xs = String.fromCharCode(i + 65);
                        }
                        ys = board.size - i;
                    } else if (coordSystem == 2) {
                        xs = i + 1;
                        ys = i + 1;
                    } else if (coordSystem == 3) {
                        xs = String.fromCharCode(i + 65);
                        ys = i + 1;
                    }

                    tmp = Math.round(board.getX(i)) - board.ls;
                    this.contextGrid.moveTo(tmp, ty);
                    this.contextGrid.lineTo(tmp, ty + bh);
                    if (coordSystem != 4) {
                        this.contextGrid.fillText(xs, tmp, rey);
                    }
                    tmp = Math.round(board.getY(i)) - board.ls;
                    this.contextGrid.moveTo(tx, tmp);
                    this.contextGrid.lineTo(tx + bw, tmp);
                    if (coordSystem != 4) {
                        this.contextGrid.fillText(ys, rex, tmp + rep);
                    }
                }
                if (coordSystem == 1) {
                    xs = String.fromCharCode(i + 66);
                    ys = 1
                } else if (coordSystem == 2) {
                    xs = board.size;
                    ys = board.size;
                } else if (coordSystem == 3) {
                    xs = String.fromCharCode(i + 65);
                    ys = board.size;
                }
                if (coordSystem != 4) {
                    this.contextGrid.fillText(xs, Math.round(board.getX(i)) - board.ls, rey);
                    this.contextGrid.fillText(ys, rex, Math.round(board.getY(i)) - board.ls + rep);
                }
                this.contextGrid.stroke();
                // draw stars
                this.contextGrid.fillStyle = this.theme_variable("starColor", board);

                if (board.starPoints[board.size]) {
                    for (var key in board.starPoints[board.size]) {
                        this.contextGrid.beginPath();
                        this.contextGrid.arc(board.getX(board.starPoints[board.size][key].x) - board.ls, board.getY(board.starPoints[board.size][key].y) - board.ls, this.theme_variable("starSize", board), 0, 2 * Math.PI, true);
                        this.contextGrid.fill();
                    }
                }
                await this._common.nextTick.call(this)

                // this.gamContext = document.getElementById(this.getId('board-canvas-gam')).getContext('2d')
                // this.gamContextReview = document.getElementById(this.getId('board-canvas-review')).getContext('2d')
                t = (new Date()).getSeconds()
                this.setDebugInto({gridend: `gridend: ${t}`})
                this.board.setContext({
                    gamContext: this.gamContext,
                    gamContextReview: this.gamContextReview,
                })
            },
            theme_variable(key, board) {
                return typeof board.theme[key] == "function" ? board.theme[key](board) : board.theme[key];
            },
            boardSizeChange(){
                this.gridInit()
            },
            destroyCanvas(){
                if(!this.board) return
                this.board.board.destroy()
            }
        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }