<!--  -->
<template>
    <div class='com-board no-selected'>
        <div class="_le-board-box" ref="boardBox" 
        @touchstart="boardTouchdownAc" @touchend="boardTouchupAc" @touchmove="boardTouchmoveAc" @touchleave="boardTouchleaveAc"
        @mousedown="boardMousedownAc" @mouseup="boardMouseupAc" @mousemove="boardMousemoveAc"
        @mouseleave="boardMouseleaveAc"
        >
            <!-- <canvas id="board-canvas-review" class="board-canvas-bcg review" :width="boardWidth" :height="boardHeight"></canvas>
            <canvas id="board-canvas-grid" class="board-canvas-bcg grid" :width="boardWidth" :height="boardHeight" ref="board-canvas-grid"></canvas> -->
        </div>
        <!-- <canvas id="board-canvas-bcg" class="board-canvas-bcg" :width="boardWidth" :height="boardHeight"></canvas>
        <canvas id="board-canvas-gam" class="board-canvas-bcg gam" :width="boardWidth" :height="boardHeight"></canvas> -->
        <!-- <debugBox ref="debugBox" :data.sync="debugData"></debugBox> -->
    </div>
</template>

<script>
    import board from '@/plugins/board/board.js'
    import boardTool from './tool/board'
    
    export default {
        name: '',
        props: {
            minNum: Number,
            boardId: {
                type: String,
                default: 'board'
            },
            boardSize: {
                type: [Number, String],
                default: 19
            },
            disabledMoveConfirm: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                board: null,
                gamePlayCoor: {},
                libertyCoor: {},
                touchBoardOpt: {},
                boardWidth: 0,
                boardHeight: 0,
                gamContext: null,
                gamContextReview: null,
                boardDown: false,
                boardDownCoord: {},
                boardMoveCoord: {},
                debugData: null,
                gameLabelCoor: {},
                preData: [],
                pageState: false,
                debugInfoList: {},
                ...boardTool.data()
            };
        },
        methods: {
            setDebugInto(items){
                for(let i in items){
                    this.$set(this.debugInfoList, i, items[i])
                }
            },
            clearDebugInfo(){
                for(let i in this.debugInfoList){
                    this.$delete(this.debugInfoList, i)
                }
            },
            debugInfo(ex){
                let time = new Date()
                time = `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`
                let text = `${time}: `
                let {debugInfoList: list} = this
                let tem = Object.values(list)
                text += tem.join(', ')
                this._common.setDebugData(text)
            },
            async pageInit(options){
                this.setDebugInto({pageInit: 'pageInit'})
                await this._common.nextTick.call(this)
                // await this.extendCanvasHand()
                
                await this.boardInit(options)
                this.boardWidth = this.board.board.width
                this.boardHeight = this.board.board.height
                await this.createCanvas()
                await this.gridInit(this.board.board)
                // this.gamContext = document.getElementById('board-canvas-gam').getContext('2d')
                // this.gamContextReview = document.getElementById('board-canvas-review').getContext('2d')
                this.$emit('emitEvent', {
                    fun: 'boardLoaded',
                    data: this.board
                })
            },
            reviewCanvasInit(){
                let ele = document.getElementById('board-canvas-review')
            },
            async extendCanvasHand(){
                let list = [
                    'board-canvas-gam',
                    'board-canvas-bcg'
                ]
                for(let i in list){
                    let ele = document.getElementById(list[i])
                    if(!ele) continue
                    this.$refs['boardBox'].appendChild(ele)
                }
                await this._common.nextTick.call(this)
            },
            async boardInit(){
                await this._common.nextTick.call(this)
                let boardEle = this.$refs['boardBox']
                let options = {
                    width: boardEle.offsetWidth,
                    height:boardEle.offsetWidth,
                    size: this.boardSize,
                    section:{top: 12, right: 0, bottom: 0, left: 12},
                    gamContext: this.gamContext,
                    gamContextReview: this.gamContextReview,
                    playByGameLabelCall: this.playByGameLabelCall,
                    posHasChangedCom: this.posHasChanged
                }
                this.board = new board(boardEle, options)
                this.boardEventSet()
                
            },
            // gamePlayCheck(x, y){
            //     let {gamePlayCoor} = this
            //     this.cancelPlay()
            //     if(gamePlayCoor.x != x || gamePlayCoor.y != y){
            //         return true
            //     }
            //     return false
            // },
            gamePlayCheck(x, y){
                let {gamePlayCoor} = this
                if(gamePlayCoor.x != x || gamePlayCoor.y != y){
                    this.cancelPlay()
                    return true
                }
                return false
            },
            gamePlayCheckLiberty(x, y){
                let {libertyCoor} = this
                let coorIndex = this.board.coor2to1(x, y)
                if(libertyCoor[coorIndex]){
                    this.cancelPlayLiberty(libertyCoor[coorIndex].x, libertyCoor[coorIndex].y)
                    return false
                }
                return true
            },
            playByGameLiberty(x, y, opt){
                if(!this.gamePlayCheckLiberty(x, y)) return
                let promise = new Promise(success => {
                    let status = this.board.playByGameLiberty(x, y, opt)
                    let coorIndex = this.board.coor2to1(x, y)
                    if(status){
                        let data = this.libertyCoor || {}
                        data = this._common.deepCopy(data)
                        data[coorIndex] = {x, y}
                        this.libertyCoor = data
                    }
                    this.playResponse = success
                })
                return promise

            },
            confirmPlayLibery(){
            },
            async cancelPlayLiberty(x, y){
                this.board.cancelPlay(x, y)
                let coorIndex = this.board.coor2to1(x, y)
                let data = this.libertyCoor || {}
                delete data[coorIndex]
                this.libertyCoor = {}
                await this._common.nextTick.call(this)
                this.libertyCoor = data
            },
            playByGame(x, y, opt){
                if(!this.gamePlayCheck(x, y)) return
                let promise = new Promise(success => {
                    this.gamePlayCoor = {x, y}
                    let status = this.board.playByGame(x, y)
                    // if(status) this.gamePlayCoor = {x, y}
                    if(!status) this.gamePlayCoor = {}
                    else this.playVoiceStone(x, y, opt)
                    this.playResponse = success
                })
                return promise
            },
            confirmPlay(){
                let {x, y, type = 'game'} = this.gamePlayCoor
                if(x === undefined || y === undefined) return
                this.clearGamLabel()
                let status = this.playAction(x, y)
                if(this.playResponse){
                    this.playResponse(status)
                }
                
                // this.gamePlayCoor = {}
                return status
            },
            cancelPlay(){
                this.clearGamLabel()
                if(this.playResponse) this.playResponse(false)
            },
            clearGamLabel(){
                let {x, y} = this.gamePlayCoor
                this.board.cancelPlay(x, y)
                this.gamePlayCoor = {}
            },
            boardTouchStart(e){
                this.touchBoardOpt.active = true
                this.touchBoardOpt.x = e.touches[0].pageX
                this.touchBoardOpt.y = e.touches[0].pageY
            },
            boardTouchMove(e){
                if(!this.touchBoardOpt.active) return
                let {gamePlayCoor} = this
                if(Object.keys(gamePlayCoor).length){
                    if (e && e.preventDefault) {
                        e.preventDefault()
                    }else{
                        window.event.returnValue = false;
                    }
                }
                let y = e.touches[0].pageY;
                let re = y - this.touchBoardOpt.y
                if(Math.abs(re) >= 60){
                    this.boardTouchEnd()
                    if(re<0){
                        this.cancelPlay()
                    }else{
                        this.confirmPlay()
                    }
                }
            },
            boardTouchEnd(e){
                document.body.addEventListener('touchmove' , function(e){ 
                    e.preventDefault(); 
                })
                this.touchBoardOpt.active = false
            },
            playAction(x, y, opt){
                let coor = this.coorCheck(x, y)
                x = coor.x
                y = coor.y
                let status = this.board.playByclick(x, y)
                if(!status){
                    this.playLastNumCheck(x, y)
                }
                this.posHasChanged()
                let {AIPlay} = opt || {}
                if(AIPlay && status) this.playVoice('stone')
                return status
            },
            playLastNumCheck(x, y){
                let step = this.board.step
                let p = this.board.position
                let f = false
                if(this.isInStone(x, y) && step[p].x == x && step[p].y == y){
                    f = true
                    this.$emit('emitEvent', {
                        fun: 'jumpTo',
                        data: -1
                    })
                }
                return f
            },
            isInStone(x, y){
                let stone = this.board.stone
                return stone[this.board.coor2to1(x, y)] != 0
            },
            posHasChanged(){
                let data = this.board.posHasChanged()
                this.$emit('emitEvent', {
                    fun: 'update',
                    data
                })
            },
            clearBoard(){
                this.board.clearBoard()
                this.posHasChanged()
            },
            getSituation(p){
                if(!this.board) return ''
                return this.board.getSituation(p)
            },
            getSituationSync(p){
                if(!this.board) return ''
                return this.board.getSituationSync(p)
            },
            isInBoard(x, y){
                if(!this.board) return false
                let coor = this.coorCheck(x, y)
                x = coor.x
                y = coor.y
                if(isNaN(x) || isNaN(y)) return false
                return this.board.isInBoard(x, y)
            },
            coorCheck(x, y){
                if(y === undefined){
                    let coor = this.board.coor1to2(x)
                    x = coor.x
                    y = coor.y
                }
                return {x, y}
            },
            changeLabel(label){
                if(!this.board) return
                this.board.changeLabel(label)
                this.posHasChanged()
            },
            changeCoor(n){
                this.board.changeCoor(n)
            },
            loadSgf(sgfData, callStep, opt){
                let f = this.board.loadSgf(sgfData, callStep, opt)
                this.posHasChanged()
                return f
            },
            setStartBoard(){},
            CreateSgfData(opt){
                return this.board.CreateSgfData(opt)
            },
            pass(){
                this.board.passByClick()
                this.posHasChanged()
                return true
            },
            async passBySocialGame(opt){
                let res = await this.passConfirm(opt)
                if(!res) return false
                this.cancelPlay()
                let color = this.board.now == 1 ? 'B': 'W'
                return this.passAction()
            },
            passAction(){
                let sta = this.boardStepCheck()
                if(!sta) return false
                this.board.passByClick()
                this.posHasChanged()
                return true
            },
            boardStepCheck(){
                let {step} = this.board
                step = this._common.deepCopy(step)
                step = step.splice(0, Number(this.board.position) + 1)
                return this._common.sgfLengthCheckSync(step)
            },
            async passConfirm(opt){
                let {doubleCheck = true, message = this._errorCode.game['pass confirm']} = opt || {}
                let step = this.board.step
                let position = this.board.position
                let buttons = [
                    {title: '确定', label: 0},
                    {title: '取消', label: 1, type: 'cancel'},
                ]
                let lastStep = step[position]
                let f = (position > 0 && lastStep.x == -1 && lastStep.y == -1 && doubleCheck)
                if(f){
                    message = '禁止连续“停一手”，是否回退？'
                    button[0].name = '回退'
                }
                let res = await this._common.confirmPro.open({
                    message,
                    confirmType: 'c3',
                    buttons
                })
                res = res || {}
                if(res.label != 0) return false
                if(f){
                    if(step.length - 1 != position) this.board.checkPosition()
                    this.back(1)
                    this.$emit('takeFun', {
                        fun: 'syncBoard'
                    })
                    return false
                }
                return true
                
            },
            gameJudgeOn(data){
                return this.board.gameJudgeOn(data)
            },
            gameJudgeOffPlay(){
                this.board.gameJudgeOffPlay()
            },
            parseSgfWithBranch(data){
                return this.board.parseSgfWithBranch(data)
            },
            setAWandAB(type, data){
                return this.board.setAWandAB(type, data)
            },
            propOff(){
                this.board.propOff()
            },
            stoneOver(x, y, c){
                this.boardEnterEvent = true
                this.board.stoneOver(x, y, c)
            },
            removeOutline(){
                this.boardEnterEvent = false
                this.board.removeOutline()
            },
            branchOn(data){
                return this.board.branchOn(data)
            },
            branchOff(){
                return this.board.branchOff()
            },
            async boardBcgInit(){
                await this._common.nextTick.call(this)
                let board = this.board.board
                let {boardWidth: w, boardHeight: h} = this
                let canvas = document.getElementById('board-canvas-bcg')
                let ctx = canvas.getContext('2d')
                let {left, top, fieldHeight: sh, fieldWidth: sw} = board
                let right = board.width - ((board.size - 1) * sw + left)
                let bottom = board.height - top - sh * (board.size - 1)
                ctx.beginPath()
                let list = [
                    {
                        move: {x: 0, y: 0},
                        lineWidth: top,
                        line: [
                            {x: w, y: 0},
                        ]
                    },
                    {
                        move: {x: w, y: 0},
                        lineWidth: right,
                        line: [
                            {x: w, y: h},
                        ]
                    },
                    {
                        move: {x: w, y: h},
                        lineWidth: bottom,
                        line: [
                            {x: 0, y: h},
                        ]
                    },
                    {
                        move: {x: 0, y: h},
                        lineWidth: left,
                        line: [
                            {x: 0, y: 0},
                        ]
                    },
                ]
                for(let i in list){
                    ctx.beginPath()
                    let {move: {x, y}, line, lineWidth} = list[i]
                    ctx.moveTo(x, y)
                    for(let j in line){
                        let {x, y} = line[j]
                        ctx.lineTo(x, y)
                    }
                    ctx.strokeStyle = '#fff';
                    ctx.lineWidth = lineWidth * 2;
                    ctx.stroke()
                }
                // this.drawBoardOverlay()

                
                
            },
            drawBoardOverlay(){
                let board = this.board.board
                let {left, top, fieldHeight: sh, fieldWidth: sw} = board
                top = top - 1, left = left - 1
                let w = board.width
                let h = board.height
                let r = Math.round(board.fieldWidth * (board.size - 1)) * 0.027
                let canvas = document.getElementById('board-canvas-bcg')
                let ctx = canvas.getContext('2d')

                let list = [
                    {
                        line: [
                            {
                                fun: 'moveTo', params: [0, 0]
                            },
                            {
                                fun: 'lineTo', params: [0, top + sh]
                            },
                            {
                                fun: 'lineTo', params:[left, top + r]
                            },
                            {
                                fun: 'arc', params: [left + r, top + r, r, Math.PI,  Math.PI * 3 / 2]
                            },
                            {
                                fun: 'lineTo', params: [left + sw, top]
                            },
                            {
                                fun: 'lineTo', params: [left + sw, 0]
                            },
                            {
                                fun: 'lineTo', params: [0, 0]
                            },
                        ]
                    },
                    {
                        line: [
                            {
                                fun: 'moveTo', params: [w, 0]
                            },
                            {
                                fun: 'lineTo', params: [w - left - sw, 0]
                            },
                            {
                                fun: 'lineTo', params: [w - left - sw, top]
                            },
                            {
                                fun: 'lineTo', params: [w - left - r, top]
                            },
                            {
                                fun: 'arc', params: [w - left - r, top + r, r, Math.PI * 3 / 2, 0]
                            },
                            {
                                fun: 'lineTo', params: [w - left, top + r]
                            },
                            {
                                fun: 'lineTo', params: [w - left, top + sh]
                            },
                            {
                                fun: 'lineTo', params: [w, top + sh]
                            },
                            {
                                fun: 'lineTo', params: [w, 0]
                            }
                        ]
                    },
                    {
                        line: [
                            {
                                fun: 'moveTo', params: [w, h]
                            },
                            {
                                fun: 'lineTo', params: [w, h - top - sh]
                            },
                            {
                                fun: 'lineTo', params: [w - left, h - top - sh]
                            },
                            {
                                fun: 'lineTo', params: [w - left, h - top - r]
                            },
                            {
                                fun: 'arc', params: [w - left - r, h - top - r, r, 0, Math.PI / 2]
                            },
                            {
                                fun: 'lineTo', params: [w - left - r, h - top]
                            },
                            {
                                fun: 'lineTo', params: [w - left - sw, h - top]
                            },
                            {
                                fun: 'lineTo', params: [w - left - sw, h]
                            },
                            {
                                fun: 'lineTo', params: [w, h]
                            }
                        ]
                    },
                    {
                        line: [
                            {
                                fun: 'moveTo', params: [0, h]
                            },
                            {
                                fun: 'lineTo', params: [left + sw, h]
                            },
                            {
                                fun: 'lineTo', params: [left + sw, h - top]
                            },
                            {
                                fun: 'lineTo', params: [left + r, h - top]
                            },
                            {
                                fun: 'arc', params: [left + r, h - top - r, r, Math.PI / 2, Math.PI]
                            },
                            {
                                fun: 'lineTo', params: [left, h - top - r]
                            },
                            {
                                fun: 'lineTo', params: [left, h - top - sh]
                            },
                            {
                                fun: 'lineTo', params: [0, h - top - sh]
                            },
                            {
                                fun: 'lineTo', params: [0, h]
                            }
                        ]
                    }
                    

                ]
                ctx.fillStyle = '#fff'
                for(let i in list){
                    let {line} = list[i]
                    ctx.beginPath()
                    for(let j in line){
                        let {fun, params} = line[j]
                        ctx[fun](...params)
                    }
                    ctx.closePath()
                    ctx.fill()
                    // let {fun, params} = list[i]
                    // ctx[fun](...params)
                }
                // ctx.fill()
            },
            clearAllLiberty(){
                this.libertyCoor = {}
            },
            boardEventSet(){
            },

            boardTouchdownAc(e){
                if(!this._common.isMobile()) return
                let {x, y} = this.board.board.getMousePos(e)
                this.boardMousedown(x, y)
            },
            boardTouchupAc(e){
                if(!this._common.isMobile()) return
                let {x, y} = this.board.board.getMousePos(e)
                this.boardMouseup(x, y)
            },
            boardTouchmoveAc(e){
                if(!this._common.isMobile()) return
                let {x, y} = this.board.board.getMousePos(e)
                this.boardMousemove(x, y)
            },
            boardTouchleaveAc(e){
                if(!this._common.isMobile() || !this.boardDown) return
                this.boardMouseup()
                this.cancelPlay()
            },

            boardMousedownAc(e){
                if(this._common.isMobile()) return
                let {x, y} = this.board.board.getMousePos(e)
                this.boardMousedown(x, y)
            },
            boardMouseupAc(e){
                if(this._common.isMobile()) return
                let {x, y} = this.board.board.getMousePos(e)
                this.boardMouseup(x, y)
            },
            boardMousemoveAc(e){
                if(this._common.isMobile()) return
                let {x, y} = this.board.board.getMousePos(e)
                this.boardMousemove(x, y)
            },
            boardMouseleaveAc(e){
                if(this._common.isMobile() || !this.boardDown) return
                this.boardMouseup()
                this.cancelPlay()
            },
            boardMousedown(x, y){
                this.boardDown = true
                if(this.playCoordWasSet){
                    let {x: dx, y: dy} = this.gamePlayCoor || {}
                    if(dx == x && dy == y) this.boardDownCoord = {x, y}
                }
                this.boardDownAction(x, y, {source: 'down'})
            },
            boardMouseup(x, y){
                this.boardDown = false
                let {x: dx, y: dy} = this.boardDownCoord || {}
                this.boardDownCoord = {}
                this.boardMoveCoord = {}
                if(dx == x && dy == y) this.cancelPlay()
            },
            boardMousemove(x, y){
                if(this.disabledMoveConfirm) return
                if(!this.boardDown) return
                this.boardDownAction(x, y)
            },
            boardDownAction(x, y, opt){
                opt = opt || {}
                let {boardMoveCoord}  = this
                let {x: sx, y: sy} = boardMoveCoord
                if(x == sx && y == sy) return
                boardMoveCoord = {x, y}
                this.boardMoveCoord = boardMoveCoord 
                this.$emit('emitEvent', {
                    fun: 'playByclickEmit',
                    data: {x, y, ...opt}
                })
            },
            playByGameLabelCall(opt){
                let {x, y, c} = opt || {}
                if(this.gamePlayCoor.x != x || this.gamePlayCoor.y != y) return
                let img = document.createElement("img");
                img.src = `${this.PARAMS.assetsUrl}/img/board/gam.png`
                img.onload = () => {
                    if(this.gamePlayCoor.x != x || this.gamePlayCoor.y != y) return
                    this.board.setGameLabel({x, y, img})
                }
            },
            playVoice(type, opt, params){
                this._voiceHand.playAction(type, opt, params)
            },
            playVoiceStone(x, y, opt){
                let {source} = opt || {}
                if(source != 'down') return
                let coor = this.boardDownCoord
                let {x: dx, y: dy} = coor
                if(!Object.keys(coor).length) this.playVoice('stone')
            },
            jumpToByposRange(p){
                this.jumpTo(this.getCurentPosDel(p))
            },
            getCurentPosDel(p){
                let position = p - this.board.position
                if(p == 0) position = 's'
                if(p == this.board.step.length - 1) position = 'l'
                return position
            },
            jumpTo(p){
                this.board.jumpTo(p)
                this.posHasChanged()
            },
            reviewLabelSet(data){
                this.board.reviewLabelSet(data)
            },
            clearReviewLabel(){
                this.board.clearReviewLabel()
            },
            ...boardTool.methods,
            backSync(num, params){
                this.board.backSync(num, params)
                this.posHasChanged()
            },
            playBySync(x, y, opt){
                let status = this.board.playBySync(x, y, opt)
                this.posHasChanged()
            },
            
            timeRead(second, data, color){
                if(second > this.PARAMS.readSecondNum){
                    this.timeReadReset()
                    return
                }
                this.board.timeRead(second, data, color)
            },
            timeReadReset(){
                this.board.timeReadReset()
            },
            areaOn(opt, color){
                let data = this.board.areaOn(opt, color)
                this.preData = data
            },
            areaOff(){
                if(!this.board || !this.board.area.active) return
                this.preData = []
                return this.board.areaOff()
            },
            back(num){
                this.board.back(num)
                this.posHasChanged()
            },
            tryPlayCon(){
                this.board.tryPlayCon()
                this.posHasChanged()
            },
            tryPlayOpen(opt, params){
                this.board.tryPlayOpen(opt)
                this.posHasChanged()
                this.tryPlayOpenAfter(params)
            },
            tryPlayClose(){
                this.board.tryPlayClose()
                this.posHasChanged()
                this.$emit('emitEvent', {
                    fun: 'clearStatusForTryPlay'
                })
            },
            tryPlayOpenAfter(params){
                this.$emit('emitEvent', {
                    fun: 'clearStatusForTryPlay'
                })
            },
        },
        created() {
            this.setDebugInto({created: 'created'})
            // this.pageInit()
        },
        components: {
        },
        computed: {
            playCoordWasSet(){
                return Object.keys(this.gamePlayCoor).length ? true : false
            },
            minNumCul(){
                return this.minNum || 0
            },
            boardInitWatch(){
                let {pageState, boardSize} = this
                if(!pageState || !boardSize || isNaN(Number(boardSize))) return false
                return true
            },
        },
        watch: {
            boardInitWatch: {
                handler(val){
                    if(!val || this.board) return
                    this.pageInit()
                },
                deep: true,
                immediate: true
            },
            boardSize: {
                handler(val){
                    if(!this.board) return
                    if(this.board.boardSize == val) return
                    this.board.setSize(val)
                    this.boardSizeChange()
                    this.$nextTick(() => {
                        this.$emit('boardSizeChange')
                    })
                    
                },
                deep: true,
                immediate: true
            },
            gamePlayCoor: {
                handler(val){
                    this.$emit('emitEvent', {
                        fun: 'update',
                        data: {gamePlayCoor: val}
                    })
                },
                deep: true,
                immediate: true
            },
            libertyCoor: {
                handler(val){
                    this.$emit('emitEvent', {
                        fun: 'update',
                        data: {libertyCoor: val}
                    })
                },
                deep: true,
                immediate: true
            },
            minNumCul: {
                handler(){
                    if(!this.board) return
                    this.board.setMinNum(this.minNumCul)
                },
                deep: true,
                immediate: true
            },
            debugInfoList: {
                handler(){
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
            this.pageState = true
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.destroyCanvas()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    html{
        overflow: hidden;
    }
    .com-board{
        width: 100%;
        height: 100%;
        .board-canvas-bcg{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            border-radius: 12px;
        }
        .board-canvas-bcg.grid{
            // z-index: ;
        }
        .board-canvas-bcg.gam{
            z-index: 101;
        }
        .board-canvas-bcg.review{
            z-index: 301;
        }
        ._le-board-box{
            width: 100%;
            height: 100%;
            position: absolute;
            // background: none;
            .wgo-board{
                width: 100% !important;
                height: 100% !important;
                canvas{
                    width: 100% !important;
                    height: 100% !important;
                    left: 0px;
                    top: 0px;
                }
            }
        }
    }
</style>